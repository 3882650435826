import React from "react";
import { Motion, spring } from "react-motion";
import { getMatrixPosition, getVisualPosition } from "./helpers";
import { TILE_COUNT, GRID_SIZE } from "./constants"

function Tile(props) {
  const { tile, index, width, height, handleTileClick, imgName } = props;
  const { row, col } = getMatrixPosition(index);
  const visualPos = getVisualPosition(row, col, width, height);
  const tileStyle = {
    width: `calc(100% / ${GRID_SIZE})`,
    height: `calc(100% / ${GRID_SIZE})`,
    translateX: visualPos.x,
    translateY: visualPos.y,
    backgroundImage: `url(/img/${imgName}-${tile}.jpg)`,
    backgroundSize: `100%`,
  };
  const motionStyle = {
    translateX: spring(visualPos.x),
    translateY: spring(visualPos.y)
  }

  return (
    <Motion style={motionStyle}>
      {({ translateX, translateY }) => (
        <li
          style={{
            ...tileStyle,
            transform: `translate3d(${translateX}px, ${translateY}px, 0)`,
            // Is last tile?
            opacity: tile === TILE_COUNT - 1 ? 0 : 1,
            cursor: tile === TILE_COUNT - 1 ? `auto` : `pointer`,
            // Is the tile in the bottom row?
            boxShadow: row === GRID_SIZE - 1 ? `0 0px #BCCFD9` : `0 10px #BCCFD9`,
          }}
          className="tile"
          onClick={() => handleTileClick(index)}
        >
          {!imgName && `${tile}`}
        </li>
      )
      }
    </Motion >
  );
}

export default Tile;
