import React, { useState } from "react";
import Tile from "./Tile";
import { TILE_COUNT, GRID_SIZE } from "./constants"
import { canSwap, shuffle, swap, isSolved } from "./helpers"

function Board({ imgName, width }) {
  const [tiles, setTiles] = useState([...Array(TILE_COUNT).keys()]);
  const [isStarted, setIsStarted] = useState(false);
  const blank = tiles.indexOf(TILE_COUNT - 1);
  const boardSize = width;

  const shuffleTiles = () => {
    const shuffledTiles = shuffle(tiles);
    setTiles(shuffledTiles);
  }

  const swapTiles = (tileIndex) => {
    if (canSwap(tileIndex, blank)) {
      const swappedTiles = swap(tiles, tileIndex, blank);
      setTiles(swappedTiles);
    }
  }

  const handleTileClick = (index) => {
    swapTiles(index);
  }

  const handleShuffleClick = () => {
    shuffleTiles();
  }

  const handleStartClick = () => {
    shuffleTiles();
    setIsStarted(true);
  }

  const pieceWidth = Math.round(boardSize / GRID_SIZE);
  const pieceHeight = Math.round(boardSize / GRID_SIZE);
  const style = {
    width: boardSize,
    height: boardSize,
  };
  const hasWon = isStarted ? isSolved(tiles) : false;

  return (
    <>
      {/* <div></div> Welcome Screen */}
      <div className="boardWrapper" style={style}>
        <ul style={style} className="board">
          {tiles.map((tile, index) => (
            <Tile
              key={tile}
              index={index}
              imgName={imgName}
              tile={tile}
              width={pieceWidth}
              height={pieceHeight}
              handleTileClick={handleTileClick}
            />
          ))}
        </ul>
      </div>

      {!isStarted &&
        <div className="full-screen-overlay">

          <div className="start-wrapper">
            <p>
              Ready? <br></br></p>
            <button className="restart" onClick={() => handleStartClick()}>  Start Game  </button>
          </div>

        </div>
      }

      {hasWon && isStarted &&

          <div className="win-wrapper">
            <p className="win-text">
              You did it! 🧠🎉</p>
            <button className="restart" onClick={() => handleShuffleClick()}>  Restart Game  </button>
          </div>

      }
  </>
  );
}

export default Board;
