import Board from "./Board";
import useWindowSize from "./useWindowSize.js";

function App() {
  const imgName = "nyc";

  const { width } = useWindowSize();
  const boardWidth = width < 600 ? 336 : 480;
  return (
    <div className="App">
        <div className="titleWrapper" style={{ width: boardWidth }}>
          <h1>Sliders</h1> <hr></hr>
        </div>
        <Board imgName={imgName} width={boardWidth} />
    </div>
  );
}

export default App;
