import { TILE_COUNT, GRID_SIZE } from "./constants"

export function countInversions(tiles) {
  let inversions = 0;
  let blank = TILE_COUNT - 1;

  for (let i = 0; i < tiles.length - 1; i++) {
    if (tiles[i] !== blank) {
      for (let j = i + 1; j < tiles.length; j++) {
        if (tiles[j] !== blank && tiles[j] < tiles[i]) inversions++;
      }
    }
  }

  return inversions;
}

export function isSolvable(tiles) {
  let inversions = countInversions(tiles);

  if (GRID_SIZE % 2 === 1) {
    return inversions % 2 === 0
  }
  else {
    let blankIndex = tiles.indexOf(TILE_COUNT - 1)
    const blankRow = Math.floor(blankIndex / GRID_SIZE)
    return (inversions % 2) !== ((blankRow) % 2)
  }
}

export function isSolved(tiles) {
  for (let i = 0, l = tiles.length; i < l; i++) {
    if (tiles[i] !== i) {
      return false;
    }
  }
  return true;
}

// Get the linear index from a row/col pair.
export function getIndex(row, col) {
  return parseInt(row, 10) * GRID_SIZE + parseInt(col, 10);
}

// Get the row/col pair from a linear index.
export function getMatrixPosition(index) {
  return {
    row: Math.floor(index / GRID_SIZE),
    col: index % GRID_SIZE,
  };
}

export function getVisualPosition(row, col, width, height) {
  return {
    x: col * width,
    y: row * height,
  };
}

export function shuffle(tiles) {
  const shuffledTiles = [
    ...tiles
      .filter((t) => t !== tiles.length)
      .sort(() => Math.random() - 0.5),
    tiles.length,
  ];
  shuffledTiles.pop();
  return isSolvable(shuffledTiles) && !isSolved(shuffledTiles)
    ? shuffledTiles
    : shuffle(shuffledTiles);
}

export function reset(tiles) {
  const arrangedTiles = [
    ...tiles.sort()
  ]
  console.log(arrangedTiles)
  return arrangedTiles
}

export function canSwap(srcIndex, destIndex) {
  const { row: srcRow, col: srcCol } = getMatrixPosition(srcIndex);
  const { row: destRow, col: destCol } = getMatrixPosition(destIndex);
  return Math.abs(srcRow - destRow) + Math.abs(srcCol - destCol) === 1;
}

export function swap(tiles, src, dest) {
  const tilesResult = [...tiles];
  [tilesResult[src], tilesResult[dest]] = [tilesResult[dest], tilesResult[src]];
  return tilesResult;
}

export function updateURLParameter(url, param, paramVal) {
  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
}
